import {
    LogLevel,
    PublicClientApplication,
    ProtocolMode,
} from '@azure/msal-browser';

const MANTY_B2C_TENANT_NAME = 'mantyb2c';
const MANTY_B2C_API_APP_ID = '7df12acc-5fe0-4fc6-8736-b47edf5c8eed';
const MANTY_B2C_SPA_APP_ID = '27d16041-addc-49d0-a70d-a4ec7d4a7526';
const MANTY_B2C_API_SCOPE = 'all.write';

const getBindB2cPolicies = (bind: string) => {
    const signUpSignInUserFlow = `B2C_1_${bind}_signup_and_signin`;

    return {
        names: {
            signUpSignIn: signUpSignInUserFlow,
        },
        authorities: {
            signUpSignIn: {
                authority: `https://${MANTY_B2C_TENANT_NAME}.b2clogin.com/${MANTY_B2C_TENANT_NAME}.onmicrosoft.com/${signUpSignInUserFlow}`,
            },
        },
        authorityDomain: `${MANTY_B2C_TENANT_NAME}.b2clogin.com`,
    };
};

const getCustomCoveSsoConfig = () => ({
    auth: {
        protocolMode: ProtocolMode.OIDC,
        authorityMetadata: JSON.stringify({
            authorization_endpoint:
                'https://auth.lacove.fr/realms/externe/protocol/openid-connect/auth',
            token_endpoint:
                'https://auth.lacove.fr/realms/externe/protocol/openid-connect/token',
            issuer: 'https://auth.lacove.fr/realms/externe',
            userinfo_endpoint:
                'https://auth.lacove.fr/realms/externe/protocol/openid-connect/userinfo',
        }),
        authority: 'https://auth.lacove.fr/realms/externe',
        clientId: 'manty',
        knownAuthorities: ['https://auth.lacove.fr/realms/externe'],
    },
});

const getBindConfig = (bind: string) => {
    if (bind === 'cove') {
        return getCustomCoveSsoConfig();
    }
    const b2cPolicies = getBindB2cPolicies(bind);

    return {
        auth: {
            clientId: MANTY_B2C_SPA_APP_ID,
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            knownAuthorities: [b2cPolicies.authorityDomain],
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                // @ts-expect-error [TS migration] Was not detected by flow
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Info:
                            // eslint-disable-next-line no-console
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            // eslint-disable-next-line no-console
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        case LogLevel.Error:
                        default:
                            console.error(message);
                    }
                },
            },
        },
    };
};

const apiConfig = {
    b2cScopes: [
        `https://${MANTY_B2C_TENANT_NAME}.onmicrosoft.com/${MANTY_B2C_API_APP_ID}/${MANTY_B2C_API_SCOPE}`,
    ],
};

export const azureLoginRequest: { scopes: Array<string> } = {
    scopes: ['openid', ...apiConfig.b2cScopes],
};

export default (bind: string): any =>
    new PublicClientApplication(getBindConfig(bind));
