import i18n from 'i18n';
import { router } from 'store/router';
import { HOME_PLACEHOLDER_DASHBOARD } from 'business/constants';
import {
    DashboardT,
    DashboardChartT,
    SharingT,
    ChartT,
} from 'business/definitions';
import { WidgetT } from 'routes/Dashboard/components/WidgetsLayout/helpers';
import { HttpError } from 'store/APIClient';
import { ChartDataT } from 'routes/Explore/hooks/updateChartThenUpdateVizSettings';
import { SettingsState } from 'routes/Explore/hooks/reducers/settingsReducer';
import { getChartData } from 'routes/Explore/hooks/reducers/helpers';

export type LayoutAPIResponseT = {
    id: number;
    widgets: WidgetT[];
};

type DashboardState = DashboardT & {
    widgets: WidgetT[];
};

const dashboardState: DashboardState = {
    key: -1,
    canEdit: false,
    slug: '',
    title: '',
    widgets: [],
    tags: [],
    usersSharing: [],
    isArchived: false,
    isTemplate: false,
    isTutorial: false,
    groupSharing: {
        isPublic: false,
        isRestricted: false,
        isRestrictedEditAccess: false,
    },

    isUserFavourite: false,
    owner: {
        id: -1,
        email: '',
    },
    isInPresentationMode: false,
};

const dashboardModel = {
    state: dashboardState,
    reducers: {
        setDashboard(
            state: DashboardState,
            payload: { dashboard: DashboardT },
        ): DashboardState {
            return {
                ...state,
                ...payload.dashboard,
            };
        },
        setSharings(
            state: DashboardState,
            payload: { sharing: SharingT },
        ): DashboardState {
            return {
                ...state,
                usersSharing: payload.sharing.usersSharing,
                groupSharing: payload.sharing.groupSharing,
            };
        },
        setWidgets(
            state: DashboardState,
            payload: { widgets: WidgetT[] },
        ): DashboardState {
            return {
                ...state,
                widgets: payload.widgets,
            };
        },
        setWidgetContent(
            state: DashboardState,
            payload: { key: WidgetT['key']; content: FlowAnyObject },
        ): DashboardState {
            return {
                ...state,
                widgets: state.widgets.map((w) =>
                    w.key === payload.key
                        ? { ...w, content: payload.content }
                        : w,
                ),
            };
        },
        deleteWidget(
            state: DashboardState,
            payload: { key: number },
        ): DashboardState {
            return {
                ...state,
                widgets: state.widgets.filter((w) => w.key !== payload.key),
            };
        },
        setChart(
            state: DashboardState,
            payload: { chartData: DashboardChartT },
        ): DashboardState {
            const { chartData } = payload;
            const chartKey = chartData.key;
            // update the widget of type chart whose key is chartKey
            const widgets = state.widgets.map((widget) => {
                if (
                    widget.type === 'chart' &&
                    widget.chart !== undefined &&
                    widget.chart.key === chartKey
                ) {
                    return {
                        ...widget,
                        chart: { ...widget.chart, ...chartData },
                    };
                }
                return widget;
            });

            return { ...state, widgets };
        },
        // FIXME: remove this and use the same function to delete all types of widgets
        deleteChartFromDashboard(
            state: DashboardState,
            payload: { chartKey: number },
        ): DashboardState {
            const widgets = state.widgets.filter(
                (w) =>
                    (w.type === 'chart' &&
                        w.chart !== undefined &&
                        w.chart.key !== payload.chartKey) ||
                    w.type !== 'chart',
            );

            return { ...state, widgets };
        },
    },
    // dispatch is 'any' until we upgrade rematch to correctly type it
    effects: (dispatch: any) => ({
        async fetchDashboard({
            slug,
            publicToken,
            homeDashboardSlug,
            isAuthenticated,
        }: {
            slug: string | undefined;
            publicToken: string;
            homeDashboardSlug: number;
            isAuthenticated: boolean;
        }) {
            const convertedSlug =
                slug === HOME_PLACEHOLDER_DASHBOARD && isAuthenticated
                    ? homeDashboardSlug
                    : slug;

            const query =
                publicToken && !isAuthenticated
                    ? `?public_token=${publicToken}`
                    : '';

            // We want to throw if the user is forbidden to access the dashboard.
            // We catch the Error in the Dashboard component and then render the <Forbidden/> component
            const onFailure = (
                statusCode: number,
                content: { message: string },
            ) => {
                if (statusCode === 403) {
                    throw new HttpError(403, content.message);
                }
            };

            const getDashboard = dispatch.API.get({
                params: {
                    endpoint: `explore/dashboard/${convertedSlug}${query}`,
                },
                failureAction: onFailure,
            });
            const getLayout = dispatch.API.get({
                params: {
                    endpoint: `explore/dashboard/${convertedSlug}/layout${query}`,
                },
                failureAction: onFailure,
            });

            const allGets = Promise.all([getDashboard, getLayout]);
            const allAnswers = await allGets;

            const dashboard = allAnswers[0].data;
            if (!dashboard) {
                console.error(
                    `Calling setDashboard with an undefined dashboard within fetchDashboard for query ${query}`,
                );
            }
            const layout = allAnswers[1].data;
            this.setDashboard({
                dashboard: {
                    ...dashboard,
                    layoutId: layout.id,
                },
            });
            this.setWidgets({ widgets: layout.widgets });

            return getDashboard;
        },

        // updateChartFromExplorer updates the chart with the data from the explore store
        async updateChartFromExplorer(payload: {
            key: number;
            explore: ExploreQueryT;
            // TODO during dashboard redux migration : stop injecting settings here, but import (useSettingsContext) it in the future hook
            settings: SettingsState;
            // TODO during dashboard redux migration : stop injecting updateChartThenUpdateVizSettings here, but import it (useUpdateChartThenUpdateVizSettings) in the future hook
            redirect: boolean;
            dashboardSlug: string | undefined;
            updateChartThenUpdateVizSettings: (payload: {
                chartData: ChartT | ChartDataT;
                chartKey: number;
                redirect: boolean;
                dashboardSlug: string | undefined;
            }) => void;
        }) {
            const {
                key,
                explore,
                settings,
                updateChartThenUpdateVizSettings,
                redirect,
                dashboardSlug,
            } = payload;
            const chartData = {
                key,
                ...getChartData(explore, settings),
            };

            this.setChart({ chartData });
            updateChartThenUpdateVizSettings({
                chartData,
                chartKey: key,
                redirect,
                dashboardSlug,
            });
        },

        // updateChart updates the chart with the data given as argument of the function
        async updateChart(payload: {
            key: number;
            chartData: Partial<DashboardChartT>;
            // TODO during redux migration : stop injecting updateChart here, but import it in the future hook
            updateChartThenUpdateVizSettings: (payload: {
                chartData: Partial<ChartT> | Partial<ChartDataT>;
                chartKey: number;
            }) => void;
        }) {
            const { key, chartData, updateChartThenUpdateVizSettings } =
                payload;
            this.setChart({ chartData: { key, ...chartData } });
            updateChartThenUpdateVizSettings({ chartData, chartKey: key });
        },

        async duplicateChart(
            payload: {
                widgetKey: number;
                dashboardSlug: string;
                updateDashboardOnSuccess: (
                    key: number,
                    updatedProps: Partial<DashboardT>,
                ) => void;
            },
            rootState: { dashboard: DashboardState },
        ) {
            const { widgetKey, dashboardSlug, updateDashboardOnSuccess } =
                payload;
            const endpoint = `explore/widget/dashboard/${widgetKey}/duplicate/${dashboardSlug}`;
            const dashboardResponse = await dispatch.API.post({
                params: { endpoint },
                successNotifyAction: () => ({
                    message: i18n.t('components:common.see'),
                    onClick: () => {
                        router.navigate(`/dashboard/${dashboardSlug}`);
                    },
                }),
            });
            const dashboard = dashboardResponse.data;
            await dispatch.API.get({
                params: {
                    endpoint: `explore/dashboard/${dashboardSlug}/layout`,
                },
                successAction: (response: LayoutAPIResponseT) => {
                    if (rootState.dashboard.slug === dashboardSlug) {
                        if (!dashboard) {
                            console.error(
                                `Calling setDashboard with an undefined dashboard within duplicateChart for dashboardSlug ${dashboardSlug}, widgetKey ${widgetKey} and endpoint ${endpoint}`,
                            );
                        }

                        this.setDashboard({
                            dashboard,
                        });
                        this.setWidgets({ widgets: response.widgets });
                    }
                    updateDashboardOnSuccess(dashboard.key, dashboard);
                },
            });
        },

        async deleteChart(
            payload: { chartKey: number },
            rootState: { dashboard: DashboardState },
        ) {
            const { chartKey } = payload;
            const widget = rootState.dashboard.widgets.find(
                (w: WidgetT) =>
                    w.type === 'chart' &&
                    w.chart !== undefined &&
                    w.chart.key === payload.chartKey,
            );

            if (widget === undefined) {
                console.error(
                    'no widget found with the given chart key ',
                    chartKey,
                );
                return {};
            }

            return dispatch.API.delete({
                params: {
                    endpoint: `explore/widget/dashboard/${widget.key}`,
                },
                successAction: () =>
                    dispatch.dashboard.deleteChartFromDashboard({
                        chartKey,
                    }),
            });
        },

        async deleteComment(
            payload: { chartKey: number; commentId: number },
            rootState: { dashboard: DashboardState },
        ) {
            const dashboardKey = rootState.dashboard.key;
            const { chartKey, commentId } = payload;
            return dispatch.API.delete({
                params: {
                    endpoint: `explore/dashboard/${dashboardKey}/chart/${chartKey}/comment/${commentId}`,
                    payload: { chartKey, commentId },
                },
                successAction: () =>
                    dispatch.dashboard.deleteCommentFromDashboard({
                        chartKey,
                        commentId,
                    }),
            });
        },

        async updateSharings(
            payload: { sharing: SharingT; key: number },
            rootState: { dashboard: DashboardState },
        ) {
            if (payload.key === rootState.dashboard.key) {
                this.setSharings({ sharing: payload.sharing });
            }
        },
    }),
};

export default dashboardModel;
