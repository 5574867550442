import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import oops from './assets/oops.png';

import './_errorBoundary.scss';

function ErrorComponent(props: { t: TranslationT }) {
    const { t } = props;
    return (
        <div
            className="error-boundary e2e-error-boundary"
            data-testid="error-boundary"
        >
            <img src={oops} alt="no parachute" />
            <h1>{t('errorBoundary.title')}</h1>
            <div className="message">
                {t('errorBoundary.message')}
                <br />
                {t('errorBoundary.secondaryMessage')}
            </div>
            <Button
                // we cannot pass window.location.reload directly because
                // `this` must be window.location when reload() is called
                onClick={() => window.location.reload()}
                variant="outlined"
            >
                {t('common.refresh')}
            </Button>
        </div>
    );
}

export default withTranslation('components')(ErrorComponent);
