import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export default Loadable({
    loader: () => import('components/App/AppContainer'),
    loading: Loading,
    // @ts-expect-error [TS migration] (previously $FlowFixMe)
    webpackRequireWeakId: () =>
        // @ts-expect-error resolveWeak isn't found, we probably need to check out types for NodeRequire
        require.resolveWeak('components/App/AppContainer'),
});
