import _ from 'lodash';
import i18n from 'i18n';
import { UNMAPPED } from 'business/constants';
import { sortAlphaNum } from 'helpers/helpers';
import { ListTreeT } from './components/Menu/components/ItemsList';

type CategoryTableT = { categoryTable: string; software: string };

export type MappingT = TreeT & {
    id: number;
    destinationId: number | null | undefined;
    humanValue: string;
    name: string;
    isLocked?: boolean;
    isActive?: boolean;
    children: MappingT[];
};

export type DestinationT = TreeT & {
    name: string;
    id: number;
    isLocked?: boolean;
    children: DestinationT[];
};

export type MappingOptionT = {
    id: number | null | undefined;
    value: string;
    label: string;
};

type MappingsState = {
    mappingCategories: ListTreeT[];
    selectedCategory: string;
    selectedSoftware: string;
    destinations: DestinationT | null;
    mappings: MappingT | null;
};

export const getUnmappedOption = (): MappingOptionT => ({
    id: null,
    value: UNMAPPED,
    label: i18n.t(UNMAPPED),
});

const getInitialState = (): MappingsState => ({
    mappingCategories: [],
    selectedCategory: '',
    selectedSoftware: '',

    destinations: null,

    mappings: null,
});

const HISTORICAL_ORG_CHART_LIST = ['historical_org_chart_hr'];

const mappingsModel = {
    state: getInitialState(),
    reducers: {
        setSoftwaresAndCategories(
            state: MappingsState,
            payload: CategoryTableT[],
        ): MappingsState {
            const mappingCategories: ListTreeT[] = [];
            const categoriesGrouped = _.groupBy(payload, 'categoryTable');
            Object.entries(categoriesGrouped).forEach(
                ([category, categories]) => {
                    if (HISTORICAL_ORG_CHART_LIST.includes(category)) {
                        const children: ListTreeT['children'] = categories.map(
                            (c) => ({
                                category,
                                software: c.software,
                                label: i18n.t(`mappings.${c.software}`),
                                value: [category, c.software].join(':'),
                            }),
                        );
                        const historicalOrgChartCategory =
                            mappingCategories.find(
                                (c) => c.value === 'historical_org_charts',
                            );
                        if (historicalOrgChartCategory === undefined) {
                            mappingCategories.push({
                                value: 'historical_org_charts',
                                label: i18n.t('mappings.historical_org_charts'),
                                children,
                            });
                        } else {
                            historicalOrgChartCategory.children.push(
                                ...children,
                            );
                        }
                    } else if (categories.length > 1) {
                        // category shared between several softwares
                        mappingCategories.push({
                            value: category,
                            label: i18n.t(`mappings.${category}`),
                            children: categories.map((c) => ({
                                value: c.software,
                                label: i18n.t(`mappings.${c.software}`),
                                category,
                                software: c.software,
                            })),
                        });
                    } else {
                        // category in a single software
                        const { software } = categories[0];

                        const children: ListTreeT['children'] = categories.map(
                            (c) => ({
                                value: c.categoryTable,
                                label: i18n.t(`mappings.${c.categoryTable}`),
                                software,
                                category: c.categoryTable,
                            }),
                        );

                        const listTree = mappingCategories.find(
                            (m) => m.value === software,
                        );
                        if (listTree === undefined) {
                            mappingCategories.push({
                                value: software,
                                label: i18n.t(`mappings.${software}`),
                                children,
                            });
                        } else {
                            listTree.children.push(...children);
                        }
                    }
                },
            );

            const sortedMappingCat: ListTreeT[] = mappingCategories
                .map((softwareCategory) => ({
                    ...softwareCategory,
                    children: softwareCategory.children.sort((a, b) =>
                        sortAlphaNum(a.label, b.label),
                    ),
                }))
                .sort((a, b) => {
                    // We want items with different softwares to be on top of the list
                    const aSoftwares = new Set(
                        a.children.map((c) => c.software),
                    );
                    const bSoftwares = new Set(
                        b.children.map((c) => c.software),
                    );
                    if (aSoftwares.size > 1 && bSoftwares.size > 1) {
                        return sortAlphaNum(a.label, b.label);
                    }
                    if (bSoftwares.size > 1) {
                        return 1;
                    }
                    if (aSoftwares.size > 1) {
                        return -1;
                    }
                    return sortAlphaNum(a.label, b.label);
                });

            let { selectedCategory, selectedSoftware } = state;
            // Setting default values
            if (
                !selectedCategory &&
                !selectedSoftware &&
                sortedMappingCat.length > 0 &&
                sortedMappingCat[0].children &&
                sortedMappingCat[0].children.length > 0
            ) {
                selectedCategory = sortedMappingCat[0].children[0].category;
                selectedSoftware = sortedMappingCat[0].children[0].software;
            }
            return {
                ...state,
                mappingCategories: sortedMappingCat,
                selectedCategory,
                selectedSoftware,
            };
        },
        setSoftware(state: MappingsState, software: string): MappingsState {
            return {
                ...state,
                selectedSoftware: software,
            };
        },
        setCategory(state: MappingsState, category: string): MappingsState {
            return {
                ...state,
                selectedCategory: category,
            };
        },
        setDestinations(
            state: MappingsState,
            destinations: DestinationT,
        ): MappingsState {
            return { ...state, destinations };
        },
        setMappings(state: MappingsState, mappings: MappingT[]): MappingsState {
            // @ts-expect-error [TS migration] (previously $FlowFixMe) flow upgrade: to investigate
            return { ...state, mappings };
        },
    },
    // dispatch is 'any' until we upgrade rematch to correctly type it
    effects: (dispatch: any) => ({
        async fetchCategories() {
            return dispatch.API.get({
                params: {
                    endpoint: 'mappings/categories',
                },

                successAction: this.setSoftwaresAndCategories,
            });
        },

        async fetchDestinations({ category }: { category: string }) {
            return dispatch.API.get({
                params: {
                    endpoint: `mappings/destinations/${category}`,
                },

                successAction: this.setDestinations,
            });
        },

        async addDestination(
            payload: { body: { value: string; parentId: number } },
            rootState: { mappings: MappingsState },
        ) {
            const category = rootState.mappings.selectedCategory;
            const endpoint = `mappings/destinations/${category}`;
            dispatch.API.post({
                params: { endpoint, body: payload.body },

                successAction: () => this.fetchDestinations({ category }),
            });
        },

        async updateDestination(
            payload: { id: number; body: { value: string; parentId: number } },
            rootState: { mappings: MappingsState },
        ) {
            const category = rootState.mappings.selectedCategory;
            const endpoint = `mappings/destinations/${category}/${payload.id}`;
            dispatch.API.put({
                params: { endpoint, body: payload.body },

                successAction: () => this.fetchDestinations({ category }),
            });
        },

        async deleteDestination(
            payload: { id: number },
            rootState: { mappings: MappingsState },
        ) {
            const category = rootState.mappings.selectedCategory;
            const endpoint = `mappings/destinations/${category}/${payload.id}`;
            dispatch.API.delete({
                params: { endpoint },

                successAction: () => this.fetchDestinations({ category }),
            });
        },

        async fetchMappings(payload: { category: string; software: string }) {
            const { category, software } = payload;
            await dispatch.API.get({
                params: {
                    endpoint: `mappings/source/${category}/${software}`,
                },

                successAction: this.setMappings,
            });
        },

        async updateMapping(
            payload: {
                sourceId: number;
                destinationId: number;
            },
            rootState: { mappings: MappingsState },
        ) {
            const category = rootState.mappings.selectedCategory;
            const { sourceId, destinationId } = payload;
            await dispatch.API.put({
                params: {
                    endpoint: `mappings/source/${category}/${sourceId}`,
                    body: {
                        destinationId:
                            // I am forced to do this because TreeT doesn't allow null ids
                            // but we need null ids for unmapped sources.
                            // We need to change TreeT.
                            destinationId !== -1 ? destinationId : null,
                    },
                },
                successAction: this.setMappings,
            });
        },
    }),
};

export default mappingsModel;
