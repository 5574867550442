import 'whatwg-fetch';
import { snakifyKeys, camelizeKeys } from 'helpers/syntaxHelper';
import { redirectToMaintenance } from 'routes/Maintenance/helpers';
import { MAINTENANCE_ERROR_CODE } from 'routes/Maintenance/Maintenance';

const contentTypeMatching = {
    csv: 'text/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    json: 'application/json',
    pdf: 'application/pdf',
};

/** Return true if the current page has a subdomain.
 * Example: sub.manty.eu -> true
 */
function hasSubdomain() {
    return (
        window.location.hostname &&
        window.location.hostname.split('.').length > 2
    );
}

/**
 * Return the subdomain closest to the main domain in the current page.
 * Example subsub.sub.manty.eu -> sub
 * manty.eu -> null
 * In production, the subdomain is used to determine the client's name.
 * @return {String}
 */
export function getClientBind(): string {
    if (
        process.env.FORCE_STAGING_API ||
        process.env.FORCE_STAGING_BUDGET_API ||
        process.env.FORCE_PRODUCTION_API ||
        process.env.FORCE_PRESENTATION_API
    ) {
        if (!process.env.FORCED_BIND) {
            throw new Error(
                'You need to specify a FORCED_BIND env var to use staging API locally',
            );
        }
        return process.env.FORCED_BIND;
    }

    const fullDomain = window.location.hostname;
    if (DEV && !fullDomain.startsWith('e2e')) {
        return 'app';
    }
    if (!hasSubdomain()) {
        return '';
    }
    if (
        // We have to maintain the behaviour for staging and staging-budget
        // TODO: remove this first check once we get rid of staging and staging-budget
        // Example: montigny78.staging-budget.manty.eu, montigny78.staging.manty.eu
        ['staging', 'staging-budget', 'presentation'].includes(
            fullDomain.split('.')[1],
        ) ||
        // On staging, there is an extra element separated with dots: the flavour.
        // Example: montigny78.alpha.staging.manty.eu
        fullDomain.split('.')[2] === 'staging' ||
        // On production and on dev env, there is no concept of flavour
        // Example: montigny78.manty.eu
        ['dev', 'manty'].includes(fullDomain.split('.')[1])
    ) {
        return fullDomain.split('.')[0];
    }
    // eslint-disable-next-line no-console
    console.log('Error: client name not found');
    return '';
}

type Body = { [key: string]: any };

type HttpParams = {
    endpoint: string;
    method?: string;
    body?: Body;
    headers?: { [key: string]: string };
    isNotJson?: boolean;
    throwWhenNotOk?: boolean;
    skipCaseConversion?: boolean;
    skipMaintenanceRedirection?: boolean;
    preProcessingUrlCallback?: (url: string) => string;
    preProcessingBodyCallback?: (body: Body) => Body;
    postProcessingCallback?: (payload: Content<any>) => Content<any>;
    signal?: AbortSignal;
};

type Content<T> = T & { message?: string };

export type HttpResponse<T> = {
    content: Content<T>;
    ok: boolean;
    statusCode: number | null;
    isNetworkError?: boolean;
};

// This type defines a default type for `content` in the `HttpResponse` type
export type HttpContentWithData<T> = {
    data: T;
    message: string;
};

export type HttpContentWithoutData = {
    message: string;
};

export class HttpError extends Error {
    status: number;

    constructor(status: number, message?: string) {
        super(message);
        this.status = status;
    }
}

/**
 * Function that is able to handle most of API calls.
 * @param showSuccessMessage Wether to show the message from the back in case of success
 * @param successAction A dict associated with the right action of the snackBar in case of success.
 * If set, the button on the right will display successAction.message, and will fire successAction.function when clicked.
 * @param endpoint backend endpoint
 * @param method HTTP request method
 * @param body Body of the request, only used if method != GET
 * @param headers of the request
 * @param isNotJson if true, will not JSOn serialize the request payload. Default: false
 * @param throwWhenNotOk if true, will throw on unsuccessful response (4XX or 5XX). Default: false
 * @param skipCaseConversion if true, will not camelize the response keys, nor snakify the request keys. Default: false
 * @param preProcessingUrlCallback? define an optional preprocessing to apply to the url
 * @param preProcessingBodyCallback? define an optional preprocessing to apply to the body/payload
 * @param postProcessingCallback? defines an optional postprocessing to apply to the returned payload
 * @param signal An AbortSignal to interupt the request.
 */
export default async function APICall<T = HttpContentWithData<unknown>>({
    endpoint,
    method = 'GET',
    body = {},
    headers = {},
    isNotJson,
    throwWhenNotOk = false,
    skipCaseConversion = false,
    preProcessingUrlCallback = (url) => url,
    preProcessingBodyCallback = (b) => b,
    postProcessingCallback = (content) => content,
    signal = undefined,
}: HttpParams): Promise<HttpResponse<T>> {
    if (typeof endpoint !== 'string') {
        throw new Error('Expected endpoint to be a string.');
    }

    const headersList = new Headers();
    headersList.set('Accept', 'application/json');
    headersList.set('Content-Type', 'application/json');

    const options: RequestInit = {
        method,
    };

    if (signal !== undefined) {
        options.signal = signal;
    }

    const preProcessedBody = preProcessingBodyCallback(body);

    if (isNotJson) {
        // Removing the headers, and avoiding serializing for non-JSON endpoints
        headersList.delete('Accept');
        headersList.delete('Content-Type');
        // @ts-expect-error [TS migration] (previously $FlowFixMe)
        options.body = preProcessedBody || {};
    } else if (method !== 'GET') {
        options.body = JSON.stringify(
            skipCaseConversion
                ? preProcessedBody
                : snakifyKeys(preProcessedBody),
        );
    }

    const token = localStorage.getItem('token') || null;
    if (token !== null) {
        headersList.set('Authorization', `Bearer ${token}`);
    }
    const bind = getClientBind();
    headersList.set('x-client', bind);

    Object.entries(headers).forEach(([key, value]) =>
        headersList.set(key, value),
    );
    options.headers = headersList;

    const url = `${VISION_PATH}/${preProcessingUrlCallback(endpoint)}`;

    let response;
    try {
        response = await fetch(url, options);
    } catch (err) {
        if (throwWhenNotOk) {
            throw err;
        }
        console.error(`Network error when fetching url: "${url}"`);
        console.error(err);
        // Returning the error for additional handling
        return {
            ok: false,
            isNetworkError: true,
            content: err as any,
            statusCode: null,
        };
    }

    const metaData = {
        ok: !!response.ok,
        statusCode: response.status,
    };

    // FIXME: we would like to put T here - but it causes TS errors I am not able to fix right now
    let content: any;
    let snakeContent;
    const isAPIinMaintenance = metaData.statusCode === MAINTENANCE_ERROR_CODE;
    if (isAPIinMaintenance) {
        redirectToMaintenance();
    }
    if (response.status !== 204) {
        // 204 is No Content
        // No need to read the body if the response does not carry content.
        switch (response.headers.get('content-type')) {
            case contentTypeMatching.json:
                snakeContent = await response.json();
                content = skipCaseConversion
                    ? snakeContent
                    : camelizeKeys(snakeContent);
                break;
            case contentTypeMatching.xlsx:
            case contentTypeMatching.csv:
            case contentTypeMatching.pdf:
                content = await response.blob();
                break;
            default:
                content = await response.text();
                break;
        }
    }

    if (!response.ok && throwWhenNotOk) {
        throw new HttpError(
            response.status,
            content?.message ?? 'Network Error',
        );
    }

    return {
        ...metaData,
        content: postProcessingCallback(content),
    };
}

/*
 * The HTTP client to use with React-Query -> forces APICall to throw on unsuccessful response
 * https://react-query.tanstack.com/guides/query-functions#usage-with-fetch-and-others-clients-that-do-not-throw-by-default
 */
export async function RQAPICall<T = HttpContentWithData<unknown>>(
    params: HttpParams,
): Promise<HttpResponse<T>> {
    return APICall({ ...params, throwWhenNotOk: true });
}

/*
 * Same as RQAPICall, but with a different use of the generic type.
 * RQAPICallWithData<string> is equivalent to RQAPICall<HttpContentWithData<string>>
 * As most endpoints return payloads of the form HttpContentWithData<T>, you'll want to
 * use this by default.
 */
export async function RQAPICallWithData<T = unknown>(
    params: HttpParams,
): Promise<HttpResponse<HttpContentWithData<T>>> {
    return RQAPICall<HttpContentWithData<T>>({
        ...params,
        throwWhenNotOk: true,
    });
}
