import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorComponent from 'components/ErrorBoundary/ErrorComponent';
import Typography from '@mui/material/Typography';

type Props = {
    error?: Error | null;
    pastDelay?: boolean;
    small?: boolean;
    message?: string | null;
    fullWidth?: boolean;
};

function Loading(props: Props): React.ReactElement {
    if (props.error) {
        console.error(props.error);
        return <ErrorComponent />;
    }
    if (!props.pastDelay) {
        // Modified automatically by react-loadable
        return <></>;
    }

    // center the loading image
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        padding: '0.5rem',
        flex: props.fullWidth ? 1 : 0,
    };

    return (
        <div style={containerStyle} className="manty-loader">
            <CircularProgress size={props.small ? 30 : 40} />
            {props.message && (
                <Typography sx={{ marginTop: '1rem' }}>
                    {' '}
                    {props.message}{' '}
                </Typography>
            )}
        </div>
    );
}
Loading.defaultProps = {
    error: null,
    pastDelay: true,
    small: false,
    message: null,
    fullWidth: false,
};
export default Loading;
