import { trackEvent } from 'helpers/trackerHelpers';
import * as Sentry from '@sentry/browser';
import i18n from 'i18n';
import { VisionState } from 'components/App/models';
import getBindMsal, { azureLoginRequest } from './msal';

type LoginPageState = {
    showResetPasswordForm: boolean;
    resetPasswordErrors: { [key: string]: string };
};

const loginPageState: LoginPageState = {
    showResetPasswordForm: false,
    resetPasswordErrors: {},
};

const loginPageModel = {
    state: loginPageState,
    reducers: {
        toggleResetPasswordForm(state: LoginPageState): LoginPageState {
            return {
                ...state,
                showResetPasswordForm: !state.showResetPasswordForm,
            };
        },
    },
    // dispatch is 'any' until we upgrade rematch to correctly type it
    effects: (dispatch: any) => ({
        async login(payload: {
            email: string;
            password: string;
            rememberMe: boolean;
        }) {
            trackEvent('login-attempt', { email: payload.email });
            return dispatch.API.post({
                params: {
                    endpoint: 'auth/login',
                    body: {
                        email: payload.email.toLowerCase(),
                        password: payload.password,
                        rememberMe: payload.rememberMe,
                    },
                },
                // @ts-expect-error [TS migration] Was not detected by flow
                successAction: (response) => {
                    trackEvent('login-success', { email: payload.email });
                    dispatch.vision.setAuthenticatedState(response);
                },
                // @ts-expect-error [TS migration] Was not detected by flow
                failureAction: (status, response) => {
                    trackEvent('login-failed', { email: payload.email });
                    const message =
                        status === 429
                            ? 'login.tooManyRequests'
                            : response?.message;
                    dispatch.vision.notifyUser({
                        message: i18n.t(message),
                        snackType: 'warning',
                    });
                },
            });
        },

        // eslint-disable-next-line consistent-return
        async ssoLogin(payload: any, rootState: { vision: VisionState }) {
            try {
                const { bind } = rootState.vision.client;
                const msal = getBindMsal(bind);
                const loginRequest =
                    bind === 'cove'
                        ? { scopes: ['openid'] }
                        : azureLoginRequest;
                const { accessToken } = await msal.loginPopup(loginRequest);

                trackEvent('login-attempt-sso');

                return dispatch.API.post({
                    params: {
                        endpoint: 'auth/sso_login',
                        body: { access_token: accessToken },
                    },
                    // @ts-expect-error [TS migration] Was not detected by flow
                    successAction: (response) => {
                        trackEvent('login-success-sso');
                        dispatch.vision.setAuthenticatedState(response);
                    },
                    // @ts-expect-error [TS migration] Was not detected by flow
                    failureAction: (status, response) => {
                        Sentry.captureException(
                            new Error(
                                `User failed to login using SSO.
                                Status ${status}, response: ${JSON.stringify(
                                    response,
                                )}`,
                            ),
                        );
                    },
                });
            } catch (error) {
                if (error instanceof Error) {
                    let message = i18n.t('login.ssoLoginError');
                    if (error.message.includes('interaction_in_progress')) {
                        message = i18n.t('login.interactionInProgress');
                    }
                    if (error.message.includes('invalid_client')) {
                        message = i18n.t('login.ssoLoginErrorOrTokenExpired');
                    }
                    dispatch.vision.notifyUser({
                        message,
                        snackType: 'warning',
                    });
                    if (!error.message.includes('user_cancelled')) {
                        Sentry.captureException(
                            new Error(
                                `User failed to login with sso. Error message: ${error.message}`,
                            ),
                        );
                    }
                }
            }
        },

        async requestResetPassword(payload: { email: string }) {
            trackEvent('password-reset-requested', { email: payload.email });
            return dispatch.API.post({
                params: {
                    endpoint: 'auth/request_reset_password',
                    body: { email: payload.email.toLowerCase() },
                },
                successAction: () => {
                    dispatch.loginPage.toggleResetPasswordForm();
                },
                failureAction: () => {
                    dispatch.loginPage.toggleResetPasswordForm();
                },
            });
        },
    }),
};

export default loginPageModel;
